import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@atoms/material/MaterialTypography";
import { LOFT_COLORS_VARIANTS } from "@root/src/style/colors";
import LegacyMaterialTextField from "./LegacyMaterialTextField";

const labelStyle: React.CSSProperties = {
  width: "100%",
  textAlign: "left",
  paddingBottom: "6px",
};

interface TextFieldProps {
  value: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (_: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  multiline?: boolean;
  label?: string;
  otherProps?: Record<string, any>;
  variant?: string;
}

const MaterialTextField: React.FC<TextFieldProps> = (props) => {
  const {
    value,
    disabled = false,
    placeholder = "",
    onChange,
    inputRef,
    fullWidth = true,
    multiline = false,
    label = null,
    variant = "outlined",
    ...otherProps
  } = props;

  return variant === "filled" ? (
    <LegacyMaterialTextField {...props} />
  ) : (
    <>
      {label && (
        <Typography variant="body2" style={labelStyle}>
          {label}
        </Typography>
      )}
      <TextField
        {...otherProps}
        fullWidth={fullWidth}
        variant="outlined"
        inputRef={inputRef}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        multiline={multiline}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "12px",
            boxShadow: "none",
            ...(multiline && {
              padding: "0 !important",
              backgroundColor: "inherit !important",
            }),
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${LOFT_COLORS_VARIANTS.lightGray} !important`,
          },
          "& .MuiOutlinedInput-root fieldset": {
            border: "none",
          },
          "& .MuiOutlinedInput-input": {
            fontSize: "17px",

            ...(!multiline && {
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "12px 12px !important",
            }),

            ...(multiline && {
              padding: "12px 12px 0 12px !important",
              backgroundColor: "inherit !important",
            }),
          },
          "& .MuiFormHelperText-root": {
            marginLeft: "0px",
            marginTop: "6px",
            fontWeight: "400",
            fontSize: ".75rem",
          },
        }}
      />
    </>
  );
};

export default MaterialTextField;
