import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/styles";

const BpIcon = styled("span")({
  borderRadius: 6,
  width: 24,
  height: 24,
  boxShadow: "inset 0 0 0 1px rgba(211, 211, 211, 1)",
  backgroundColor: "white",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#D3D3D3",
  },
});

const BpCheckedIcon = styled(BpIcon)(({ theme }: { theme: any }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.primary.main,
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },

  "input:disabled ~ &": {
    "&::before": {
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23A9A9A9'/%3E%3C/svg%3E\")",
    },
  },
}));

const BpIndeterminateIcon = styled(BpIcon)(({ theme }: { theme: any }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.primary.main,
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 12,
    height: 3,
    backgroundColor: "white",
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  "input:disabled ~ &": {
    "&::before": {
      backgroundColor: "#A9A9A9",
    },
  },
}));

const MaterialCheckbox: React.FC<CheckboxProps> = (props) => (
  <Checkbox
    disableRipple
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    indeterminateIcon={<BpIndeterminateIcon />}
    inputProps={{ "aria-label": "Checkbox demo" }}
    {...props}
  />
);

export default MaterialCheckbox;
