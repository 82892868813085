import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { rgba } from "polished";
import useWindowSize from "@hooks/useWindowSize";
import MaterialTypography from "@atoms/material/MaterialTypography";
import MaterialButton from "@components/atoms/material/MaterialButton";
import Spacing from "../layouts/Spacing";

const BackgroundWrapper = styled("div")`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  max-height: 250px;
  border-left: 10px solid ${({ backgroundColor }) => backgroundColor};
  background-color: ${({ backgroundColor }) => rgba(backgroundColor, 0.1)};
  border-radius: 8px;
  padding: 16px;
  position: relative;
`;

const headingStyle = { fontWeight: "bold" };
const bodyStyle = { overflow: "auto" };

const Heading = ({ children, ...otherProps }) => (
  <React.Fragment>
    <MaterialTypography align="left" {...otherProps} style={headingStyle} variant="subtitle2" color="textPrimary">
      {children}
    </MaterialTypography>
    <Spacing height="2" />
  </React.Fragment>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  _loyaltyInfoBox: PropTypes.bool,
};

Heading.defaultProps = {
  icon: null,
  _loyaltyInfoBox: false,
};

const Body = ({ children, loyaltyInfoBox = false, ...otherProps }) => (
  <MaterialTypography
    align="left"
    {...otherProps}
    style={{ ...bodyStyle, lineHeight: loyaltyInfoBox ? 1.5 : 2 }}
    variant="body1"
    color="textPrimary"
  >
    {children}
  </MaterialTypography>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  loyaltyInfoBox: PropTypes.bool,
};

Body.defaultProps = {
  loyaltyInfoBox: false,
};

export default function InfoBox({
  width,
  maxWidth,
  color,
  show,
  children,
  loyaltyInfoBox,
  crLite,
  actionTitle,
  onAction,
  onClose,
}) {
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const getThemeColor = () => {
    switch (color) {
      case "warning":
      case "urgent":
        return theme.palette.warning.main;
      case "alert":
        return theme.palette.error.main;
      case "success":
        return theme.palette.success.main;
      case "mediumGrey":
      case "secondary":
      case "primary":
      case "modernBlack":
      case "backgroundColor":
      case "crLiteBackground":
      case "info":
        return theme.palette.infoColor.main;
      default:
        return null;
    }
  };

  const loyaltyInfoBoxStyles = {
    marginLeft: isMobile ? "22px" : "10px",
    width: isMobile ? "90%" : "98%",
    maxWidth: isMobile ? maxWidth : "100%",
  };
  const marginStyles = crLite ? { marginBottom: "25px" } : { marginTop: "30px" };
  const themeColor = getThemeColor();

  if (show) {
    return (
      <BackgroundWrapper
        width={width}
        maxWidth={maxWidth}
        backgroundColor={themeColor}
        isMobile={isMobile}
        style={loyaltyInfoBox ? { ...loyaltyInfoBoxStyles, ...marginStyles } : {}}
      >
        {onClose && (
          <IconButton
            key="close"
            aria-label="Close"
            onClick={onClose}
            size="small"
            sx={{
              position: "absolute",
              top: "6px",
              right: "6px",
              transform: "scale(0.7)",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        {React.Children.map(children, (child) =>
          React.cloneElement(child, { color: child.props.color || color, crLite })
        )}

        {actionTitle && onAction && (
          <div>
            <MaterialButton
              data-testid={`action-button-click-${actionTitle.replace(/ /g, "-")}`}
              variant="text"
              onClick={onAction}
              color={color === "warning" ? theme.palette.warningCta.main : themeColor}
              style={{ marginTop: 2, padding: 0, textAlign: "left", cursor: "pointer" }}
            >
              {actionTitle}
            </MaterialButton>
          </div>
        )}
      </BackgroundWrapper>
    );
  }
  return null;
}

InfoBox.Heading = Heading;
InfoBox.Body = Body;

InfoBox.propTypes = {
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  /** The color variant to use for text, backgroud, & border */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "alert",
    "warning",
    "success",
    "urgent",
    "mediumGrey",
    "modernBlack",
    "backgroundColor",
    "crLiteBackground",
  ]),
  /** Show InfoBox controlled by parent component */
  show: PropTypes.bool,
  /** Children of whats rendered inside, one of [InfoBox.Heading, InfoBox.Body] */
  children: PropTypes.node.isRequired,
  loyaltyInfoBox: PropTypes.bool,
  crLite: PropTypes.bool,
  actionTitle: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
};

InfoBox.defaultProps = {
  width: undefined,
  maxWidth: "687px",
  color: "primary",
  show: false,
  loyaltyInfoBox: false,
  crLite: false,
  actionTitle: undefined,
  onAction: undefined,
  onClose: undefined,
};
