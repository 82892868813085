import { defaultAmenifyData, defaultTrackingAttributes, productData } from "./utils";
import { CLICK, VIEW, EARN } from "./constants";

export function vendorOptinShownTrackingAttributes() {
  return {
    page: EARN,
    event: VIEW,
    context: "VendorOptinCardShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function vendorOptinSkippedTrackingAttributes() {
  return {
    page: EARN,
    event: CLICK,
    context: "VendorOptinSkipped",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function vendorOptinJoinedTrackingAttributes() {
  return {
    page: EARN,
    event: CLICK,
    context: "VendorOptinJoined",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}
